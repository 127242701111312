import { render, staticRenderFns } from "./PortfolioMain.vue?vue&type=template&id=5cb05c05&scoped=true&"
import script from "./PortfolioMain.vue?vue&type=script&lang=js&"
export * from "./PortfolioMain.vue?vue&type=script&lang=js&"
import style0 from "./PortfolioMain.vue?vue&type=style&index=0&id=5cb05c05&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb05c05",
  null
  
)

export default component.exports